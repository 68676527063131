
import {
  defineComponent,
  onMounted,
  watch,
  computed,
  ref,
  watchEffect,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent, DrawerComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import VPagination from "@hennge/vue3-pagination";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import AppService from "@/core/services/AppService";
import { renderImageUrl } from "@/core/services/AppService";
import { size_image_thumbnail } from "@/core/config/AppConfig";
export default defineComponent({
  name: "account-list",
  components: { VPagination },
  props: {
    page: {
      type: String,
      default: "1",
    },
    limit: {
      type: String,
      default: "20",
    },
    email: {
      type: String,
      default: "",
    },
    order: {
      type: String,
      default: "desc",
    },
    order_by: {
      type: String,
      default: "created_at",
    },
    name: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    let params = {
      page: parseInt(props.page),
      limit: parseInt(props.limit),
      email: props.email,
      order: props.order,
      order_by: props.order_by,
      name: props.name,
    };
    const s_name = ref();
    const modelName = "projects";
    const limitData = [5, 10, 20, 50, 100];
    const action_get_data = "GET_ALL_PROJECT";
    store.dispatch(action_get_data, AppService.convertParams(params));
    const listData = computed(() => store.getters["listDataProject"]);
    const totalPages = computed(() => store.getters["getProjectPages"]);
    const totalDocs = computed(() => store.getters["getTotalProject"]);
    const onChangePage = async (next_page) => {
      params.page = next_page;
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        modelName
      );
    };
    const onChangeLimit = async (event) => {
      let positionMax =
        params.limit * params.page > totalDocs.value
          ? totalDocs.value
          : params.limit * params.page;
      params.limit = event.target.value;
      params.page =
        Math.ceil(positionMax / params.limit) > 0
          ? Math.ceil(positionMax / params.limit)
          : 1;
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        modelName
      );
    };
    const onClickSort = async (order_by) => {
      params.order_by = order_by;
      params.order = params.order === "desc" ? "asc" : "desc";
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        modelName
      );
    };
    const onSearch = async () => {
      let searchObject = {};
      // if (s_name.value.trim() != "") {
      //   searchObject["name"] = s_name.value;
      // }
      // params.search = JSON.stringify(searchObject);
      await AppService.getDataListObject(
        params,
        store,
        router,
        action_get_data,
        modelName
      );
    };
    const onClickDelete = (id) => {
      Swal.fire({
        text: "Bạn chắc chắn muốn xóa chứ ?",
        icon: "warning",
        buttonsStyling: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-primary",
        },
      }).then(function (result) {
        let searchString = AppService.convertParams(params);
        if (result.isConfirmed) {
          store
            .dispatch("DELETE_PROJECT", { id, searchString })
            .then(() => {
              Swal.fire({
                text: "Xóa thành công ",
                icon: "success",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 1000,
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                  cancelButton: "btn fw-bold btn-light-primary",
                },
              }).then(function (result) {
                // Go to page after successfully login
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };
    const changeStatus = (objectUpdate) => {
      objectUpdate.id = objectUpdate._id;
      objectUpdate.active =
        objectUpdate.active == "inactive" ? "active" : "inactive";
      store.dispatch("UPDATE_PROJECT", objectUpdate);
    };
    const onClickView = (id) => {
      //console.log(id);
      // DrawerComponent.initGlobalHandlers();
      // DrawerComponent.update();
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Dự án", ["Nội dung"]);
      MenuComponent.reinitialization();
      // let searchObject = JSON.parse(params.search);
      // s_name.value = searchObject.name;
    });
    const domain_FE = process.env.VUE_APP_HOME_FE;

    return {
      s_name,
      domain_FE,
      listData,
      modelName,
      params,
      limitData,
      totalDocs,
      totalPages,
      onChangePage,
      onClickView,
      changeStatus,
      onClickDelete,
      onChangeLimit,
      onClickSort,
      renderImageUrl,
      onSearch,
    };
  },
});
